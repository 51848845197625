import Navigation from 'components/navigation/Navigation';
import './App.scss';
import Routes from 'pages/Routes';

function App() {
  return (
    <div className="App">
      <Navigation />
      <Routes />
    </div>
  );
}

export default App;
