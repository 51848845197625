import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import ImgLoader from 'components/image/ImgLoader';
import { rem } from 'styles/utils';

const NAVS = [
  {
    to: '/dashboard',
    title: 'Dashboard',
  },
  {
    to: '/lockdrop',
    title: 'Lock Drop',
  },
];

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 0 ${rem(40)};
  border-bottom: ${rem(1)} solid var(--border-primary-color);
`;

const Logo = styled.div`
  display: flex;
  padding-right: ${rem(48)};
`;

const NavBars = styled.div`
  display: flex;
`;

const NavLink = styled(Link)`
  position: relative;
  margin: 0 ${rem(16)};
  padding: ${rem(22)} 0 ${rem(16)};
  font-family: Archivo SemiBold;
  line-height: 150%;

  &:hover {
    color: var(--link-hover-color);
  }
`;

const BottomLine = styled.span`
  border-bottom-width: ${rem(2)};
  border-bottom-style: solid;
  border-image: var(--border-gradient-color) 1;
  display: flex;
  width: 100%;
  height: 0;
  position: absolute;
  bottom: ${rem(-0.5)};
`;

const Navigation: FC = () => {
  const { pathname } = useLocation();

  return (
    <Container>
      <Logo>
        <ImgLoader
          name="logo-horizontal-white.svg"
          styles={{
            width: rem(156),
          }}
        />
      </Logo>
      <NavBars>
        {NAVS.map(({ to, title }) => (
          <NavLink to={to} key={title}>
            {title}
            {pathname.includes(to) && <BottomLine />}
          </NavLink>
        ))}
      </NavBars>
    </Container>
  );
};

export default Navigation;
