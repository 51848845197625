import { createSlice } from '@reduxjs/toolkit';
import {
  MOCK_SUPPLY_MARKETS,
  MOCK_BORROW_MARKETS,
  MOCK_USER_SUPPLY,
  MOCK_USER_BORROW,
} from 'constants/mocks';
import { TOKENS } from 'constants/token';
import {
  calculateAvailableBorrowing,
  getTokenValueInUSD,
  revertBackTokenUSDValue,
} from 'utils/calculators';

const getTotalCollateral = (userDeposits: typeof MOCK_USER_SUPPLY) => {
  return userDeposits.reduce((sum, { id, deposit, isCollateral }) => {
    if (isCollateral) {
      const depositInUSD = getTokenValueInUSD(id, deposit);
      return sum + depositInUSD;
    }
    return sum;
  }, 0);
};

const getTotalDebt = (userBorrows: typeof MOCK_USER_BORROW) => {
  return userBorrows.reduce((sum, { id, debt }) => {
    const debtInUSD = getTokenValueInUSD(id, debt);
    return sum + debtInUSD;
  }, 0);
};

const SupplyMarkets = TOKENS.map(({ symbol, name }) => {
  const find = MOCK_SUPPLY_MARKETS.filter((item) => item.id === symbol)[0];

  return {
    symbol,
    name,
    ...find,
  };
});

const UserSupply = MOCK_USER_SUPPLY.map((item) => {
  const find = TOKENS.filter(({ symbol }) => item.id === symbol)[0];

  return {
    ...find,
    ...item,
  };
});

const totalCollateral = getTotalCollateral(MOCK_USER_SUPPLY);

const totalDebt = getTotalDebt(MOCK_USER_BORROW);

const allowBorrow = calculateAvailableBorrowing(totalCollateral, totalDebt);

const BorrowMarkets = TOKENS.map(({ symbol, name }) => {
  const findItem = MOCK_BORROW_MARKETS.filter((item) => item.id === symbol)[0];

  const available = revertBackTokenUSDValue(symbol, allowBorrow).toString();

  return {
    symbol,
    name,
    available,
    ...findItem,
  };
});

const UserBorrow = MOCK_USER_BORROW.map((item) => {
  const find = TOKENS.filter(({ symbol }) => item.id === symbol)[0];

  return {
    ...find,
    ...item,
  };
});

interface DashboardState {
  markets: {
    supply: typeof SupplyMarkets;
    borrow: typeof BorrowMarkets;
  };
  user: {
    supply: typeof UserSupply;
    borrow: typeof UserBorrow;
  };
  form: {
    symbol: string;
    value: string;
  };
}

const initialState: DashboardState = {
  markets: {
    supply: SupplyMarkets,
    borrow: BorrowMarkets,
  },
  user: {
    supply: UserSupply,
    borrow: UserBorrow,
  },
  form: {
    symbol: '',
    value: '',
  },
};

const slice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    updateForm: (state, action) => {
      state.form = {
        ...state.form,
        ...action.payload,
      };
    },
    supplyToken: (state) => {
      const { symbol, value } = state.form;

      const userSupplyItem = state.user.supply.find((item) => item.id === symbol);
      const marketSupplyItem = state.markets.supply.filter((item) => item.id === symbol)[0];

      if (userSupplyItem) {
        userSupplyItem.deposit = (+userSupplyItem.deposit + +value).toString();
      } else {
        state.user.supply.push({
          ...marketSupplyItem,
          deposit: value,
        });
      }

      marketSupplyItem.balance = (+marketSupplyItem.balance - +value).toString();
    },
    withdrawToken: (state) => {
      const { symbol, value } = state.form;

      const userSupplyItem = state.user.supply.filter((item) => item.id === symbol)[0];
      const marketSupplyItem = state.markets.supply.filter((item) => item.id === symbol)[0];

      userSupplyItem.deposit = (+userSupplyItem.deposit - +value).toString();
      marketSupplyItem.balance = (+marketSupplyItem.balance + +value).toString();
    },
    borrowToken: (state) => {
      const { symbol, value } = state.form;

      const userBorrowItem = state.user.borrow.find((item) => item.id === symbol);
      const marketBorrowItem = state.markets.borrow.filter((item) => item.id === symbol)[0];

      if (userBorrowItem) {
        userBorrowItem.debt = (+userBorrowItem.debt + +value).toString();
      } else {
        state.user.borrow.push({
          ...marketBorrowItem,
          debt: value,
        });
      }

      const ttCollateral = getTotalCollateral(state.user.supply);
      const ttDebt = getTotalDebt(state.user.borrow);

      const allowBorrowAfter = calculateAvailableBorrowing(ttCollateral, ttDebt);

      state.markets.borrow = state.markets.borrow.map((item) => {
        const available = revertBackTokenUSDValue(item.id, allowBorrowAfter).toString();
        return {
          ...item,
          available,
        };
      });
    },
    repayToken: (state) => {
      const { symbol, value } = state.form;

      const userBorrowItem = state.user.borrow.filter((item) => item.id === symbol)[0];

      userBorrowItem.debt = (+userBorrowItem.debt - +value).toString();

      const ttCollateral = getTotalCollateral(state.user.supply);
      const ttDebt = getTotalDebt(state.user.borrow);

      const allowBorrowAfter = calculateAvailableBorrowing(ttCollateral, ttDebt);

      state.markets.borrow = state.markets.borrow.map((item) => {
        const available = revertBackTokenUSDValue(item.id, allowBorrowAfter).toString();
        return {
          ...item,
          available,
        };
      });
    },
  },
});

export const { updateForm, supplyToken, withdrawToken, borrowToken, repayToken } = slice.actions;

export default slice.reducer;
