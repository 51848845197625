import BigNumber from 'bignumber.js';
import { LTV_RATIO } from 'config';
import { TOKEN_PRICES } from 'constants/prices';

export function getTokenValueInUSD(token: string, value: string | number) {
  if (Number.isNaN(+value) || +value === 0) return Number(0);

  const price = (TOKEN_PRICES as any)[token.toLowerCase()]?.usd || 1;

  const valueInUSD = new BigNumber(value).multipliedBy(price);

  return valueInUSD.toNumber();
}

export function revertBackTokenUSDValue(token: string, value: string | number) {
  if (Number.isNaN(+value) || +value === 0) return Number(0);

  const price = (TOKEN_PRICES as any)[token.toLowerCase()]?.usd || 1;

  const tokenValue = new BigNumber(value).dividedBy(price);

  return tokenValue.toNumber();
}

export function calculateAvailableBorrowing(
  collateral: string | number,
  debt: string | number
): number {
  if (!collateral || debt === null || debt === undefined) return 0;

  const borrowLimit = new BigNumber(collateral).multipliedBy(LTV_RATIO).minus(debt || 0);
  return borrowLimit.toNumber();
}

export function calculateAvailableWithdrawal(
  collateral: string | number,
  debt: string | number
): number {
  if (!collateral || debt === null || debt === undefined) return 0;

  const ampDebt = new BigNumber(debt || 0).dividedBy(LTV_RATIO);
  const withdrawLimit = new BigNumber(collateral).minus(ampDebt);
  return withdrawLimit.toNumber();
}
