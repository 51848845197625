import { lazy, Suspense } from 'react';
import { Routes as Switch, Route, Navigate } from 'react-router-dom';

const Dashboard = lazy(() => import('./dashboard/DashboardContainer'));
const LockDrop = lazy(() => import('./lockdrop/LockDropContainer'));
const LatestEvents = lazy(() => import('./lockdrop/latest-events'));
const FinishedEvents = lazy(() => import('./lockdrop/finished-events'));
const LockedAssets = lazy(() => import('./lockdrop/locked-assets'));
const LockEvent = lazy(() => import('./lock-event/LockEventContainer'));

function Routes() {
  return (
    <Suspense>
      <Switch>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="lockdrop" element={<LockDrop />}>
          <Route path="latest" element={<LatestEvents />} />
          <Route path="finished" element={<FinishedEvents />} />
          <Route path="my-assets" element={<LockedAssets />} />
          <Route path="/lockdrop" element={<Navigate replace to="latest" />} />
        </Route>
        <Route path="lockdrop/create" element={<LockEvent />} />
        <Route path="/" element={<Navigate replace to="/dashboard" />} />
      </Switch>
    </Suspense>
  );
}

export default Routes;
