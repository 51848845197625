import { createSlice } from '@reduxjs/toolkit';
import { FINISHED_EVENTS, LATEST_EVENTS } from 'constants/events';
import { MOCK_LOCKED_ASSETS } from 'constants/mocks';

export type EventItemType = (typeof LATEST_EVENTS)[0];

interface LockdropState {
  latestEvents: EventItemType[];
  finishedEvents: EventItemType[];
  lockedAssets: typeof MOCK_LOCKED_ASSETS;
  select: EventItemType;
  form: {
    token: string;
    amount: string;
    expire: string;
    duration: number;
    multiplier: string;
  };
}

const initialState: LockdropState = {
  latestEvents: LATEST_EVENTS,
  finishedEvents: FINISHED_EVENTS,
  lockedAssets: MOCK_LOCKED_ASSETS,
  select: {} as EventItemType,
  form: {
    token: 'MILK',
    amount: '',
    expire: '',
    duration: 0,
    multiplier: '',
  },
};

const slice = createSlice({
  name: 'lockdrop',
  initialState,
  reducers: {
    setSelectedEvent: (state, action) => {
      state.select = action.payload;
    },
    updateLockForm: (state, action) => {
      state.form = {
        ...state.form,
        ...action.payload,
      };
    },
  },
});

export const { setSelectedEvent, updateLockForm } = slice.actions;

export default slice.reducer;
