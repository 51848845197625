export const TOKEN_PRICES = {
  usdc: {
    usd: 1,
  },
  milk: {
    usd: 3,
  },
  dai: {
    usd: 1,
  },
  velo: {
    usd: 0.046,
  },
  op: {
    usd: 2.15,
  },
  weth: {
    usd: 1500,
  },
};
