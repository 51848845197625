import { CSSProperties, FC } from 'react';

interface Props {
  name: string;
  className?: string;
  styles?: CSSProperties;
}

const ImgLoader: FC<Props> = ({ name, className, styles }) => {
  return <img className={className} style={styles} src={`/images/${name}`} alt="" />;
};

ImgLoader.defaultProps = {
  className: '',
  styles: {},
};

export default ImgLoader;
