const today = new Date();
const year = today.getFullYear();
const month = today.getMonth();
const day = today.getDate();

export const LATEST_EVENTS = [
  {
    id: 0,
    title: 'Base assets',
    summary:
      'Participte in the Stark Lend Lockdrop by committing USDC, USDT, DAI, WBTC, WETH and STARK.',
    content: `
      Perpetual Protocol is distributing PERP to all people who lock into vePERP. 
      The more PERP (or the longer) you lock, the greater your weekly rewards will be. 
      We will distribute an amount equal to 10 percent of the trading fees to vePERP holders as rewards on a weekly basis. 
      Your share of these rewards will be determined by the amount of vePERP you have at the time of each snapshot.
    `,
    startDate: new Date(year, month, day, 6).toISOString(),
    endDate: new Date(year, month, day + 5, 6).toISOString(),
    tvl: '$12.34M',
  },
  {
    id: 1,
    title: 'Partner projects',
    summary:
      'Participte in the Stark Lend Lockdrop by committing USDC, USDT, DAI, WBTC, WETH and STARK.',
    content: `
      Perpetual Protocol is distributing PERP to all people who lock into vePERP. 
      The more PERP (or the longer) you lock, the greater your weekly rewards will be. 
      We will distribute an amount equal to 10 percent of the trading fees to vePERP holders as rewards on a weekly basis. 
      Your share of these rewards will be determined by the amount of vePERP you have at the time of each snapshot.
    `,
    startDate: new Date(year, month + 3, day, 6).toISOString(),
    endDate: new Date(year, month + 3, day + 5, 6).toISOString(),
    tvl: '',
  },
];

export const FINISHED_EVENTS = [
  {
    id: 0,
    title: 'Base assets',
    summary:
      'Participte in the Stark Lend Lockdrop by committing USDC, USDT, DAI, WBTC, WETH and STARK.',
    content: `
      Perpetual Protocol is distributing PERP to all people who lock into vePERP. 
      The more PERP (or the longer) you lock, the greater your weekly rewards will be. 
      We will distribute an amount equal to 10 percent of the trading fees to vePERP holders as rewards on a weekly basis. 
      Your share of these rewards will be determined by the amount of vePERP you have at the time of each snapshot.
    `,
    startDate: new Date(year, month - 2, day, 6).toISOString(),
    endDate: new Date(year, month - 2, day + 5, 6).toISOString(),
    tvl: '$54.34M',
  },
  {
    id: 1,
    title: 'Partner projects',
    summary:
      'Participte in the Stark Lend Lockdrop by committing USDC, USDT, DAI, WBTC, WETH and STARK.',
    content: `
      Perpetual Protocol is distributing PERP to all people who lock into vePERP. 
      The more PERP (or the longer) you lock, the greater your weekly rewards will be. 
      We will distribute an amount equal to 10 percent of the trading fees to vePERP holders as rewards on a weekly basis. 
      Your share of these rewards will be determined by the amount of vePERP you have at the time of each snapshot.
    `,
    startDate: new Date(year, month - 3, day, 6).toISOString(),
    endDate: new Date(year, month - 3, day + 5, 6).toISOString(),
    tvl: '$12.34M',
  },
  {
    id: 2,
    title: 'Phase3 assets',
    summary:
      'Participte in the Stark Lend Lockdrop by committing USDC, USDT, DAI, WBTC, WETH and STARK.',
    content: `
      Perpetual Protocol is distributing PERP to all people who lock into vePERP. 
      The more PERP (or the longer) you lock, the greater your weekly rewards will be. 
      We will distribute an amount equal to 10 percent of the trading fees to vePERP holders as rewards on a weekly basis. 
      Your share of these rewards will be determined by the amount of vePERP you have at the time of each snapshot.
    `,
    startDate: new Date(year, month - 4, day, 6).toISOString(),
    endDate: new Date(year, month - 4, day + 5, 6).toISOString(),
    tvl: '',
  },
];
