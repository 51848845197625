export const MOCK_SUPPLY_MARKETS = [
  {
    id: 'DAI',
    balance: '80',
    apy: 0.3421,
    isCollateral: true,
  },
  {
    id: 'USDC',
    balance: '1000',
    apy: 0.2142,
    isCollateral: true,
  },
  {
    id: 'MILK',
    balance: '50',
    apy: 0.1812,
    isCollateral: true,
  },
  {
    id: 'OP',
    balance: '220.5',
    apy: 0.1564,
    isCollateral: false,
  },
  {
    id: 'WETH',
    balance: '0',
    apy: 0.1034,
    isCollateral: false,
  },
  {
    id: 'VELO',
    balance: '0.1234',
    apy: 1.2012,
    isCollateral: true,
  },
];

export const MOCK_USER_SUPPLY = [
  {
    id: 'DAI',
    deposit: '120',
    apy: 0.34,
    isCollateral: true,
  },
];

export const MOCK_BORROW_MARKETS = [
  {
    id: 'DAI',
    apy: 0.0512,
    liquidity: '$5.21M',
  },
  {
    id: 'USDC',
    apy: 0.0264,
    liquidity: '$5.21M',
  },
  {
    id: 'MILK',
    apy: 0.0821,
    liquidity: '$5.21M',
  },
  {
    id: 'OP',
    apy: 0.0425,
    liquidity: '$5.21M',
  },
  {
    id: 'WETH',
    apy: 0.0323,
    liquidity: '$5.21M',
  },
  {
    id: 'VELO',
    apy: 0.0923,
    liquidity: '$5.21M',
  },
];

export const MOCK_USER_BORROW = [
  {
    id: 'USDC',
    debt: '10',
    apy: 0.0014,
  },
];

const today = new Date();
const year = today.getFullYear();
const month = today.getMonth();
const day = today.getDate();

export const MOCK_LOCKED_ASSETS = [
  {
    id: 'USDC',
    amount: '400',
    expire: new Date(year, month + 2, day).toISOString(),
    duration: 3,
    rewards: '120.33',
  },
  {
    id: 'DAI',
    amount: '100',
    expire: new Date(year, month, day - 5).toISOString(),
    duration: 1,
    rewards: '',
  },
];

export const SELECTABLE_TOKENS = [
  {
    symbol: 'MILK',
    name: 'Milk Token',
    balance: '6302',
  },
  {
    symbol: 'USDC',
    name: 'USD Coin',
    balance: '100',
  },
  {
    symbol: 'OP',
    name: 'Optimism',
    balance: '',
  },
  {
    symbol: 'VELO',
    name: 'Velodrome',
    balance: '',
  },
];
