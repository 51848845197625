export const TOKENS = [
  {
    symbol: 'DAI',
    name: 'Dai',
  },
  {
    symbol: 'USDC',
    name: 'USD Coin',
  },
  {
    symbol: 'MILK',
    name: 'Milk Token',
  },
  {
    symbol: 'OP',
    name: 'Optimism',
  },
  {
    symbol: 'WETH',
    name: 'Wrapped Ether',
  },
  {
    symbol: 'VELO',
    name: 'Velodrome',
  },
];
